import type { ForwardedRef } from 'react';

import React, { forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import styles from './index.module.scss';

interface AlertProps {
  center?: boolean;
  className?: string;
  icon?: string;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
  type: 'danger' | 'info' | 'neutral' | 'success' | 'warning';
}

/**
 * A banner to display alerts to the user.
 */
const Alert = forwardRef(function Alert(
  { className, center = false, title, subtitle, icon, type }: AlertProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const bem = useClassy(styles, 'Alert');

  return (
    <Flex
      ref={ref}
      align="center"
      className={bem('&', `_${type}`, center && '_center', className)}
      gap="sm"
      justify="between"
    >
      <Flex align="start" gap="sm" justify="start" layout="col">
        {!!icon && <Icon className={bem(`-icon_${type}`)} name={icon} size="lg" />}
        <Flex gap={0} layout="col">
          {!!title && <span className={bem('-title')}>{title}</span>}
          {!!subtitle && <span className={bem('-subtitle')}>{subtitle}</span>}
        </Flex>
      </Flex>
    </Flex>
  );
});

export default Alert;
